<template>
  <div class="min-h-screen flex">
    <Sidebar />
    <div class="w-2/12"></div>
    <div class="p-4 w-10/12 flex flex-col is-relative">
      <b-loading
        :is-full-page="false"
        v-model="$store.getters.isProcessing"
      ></b-loading>
      <div class="flex items-center mb-4">
        <b-button
          class="mr-4"
          type="is-light"
          tag="router-link"
          :to="{ name: 'Permissions' }"
          >Back</b-button
        >
        <div class="is-size-4">{{ $route.name }}</div>
      </div>
      <div class="px-4 flex-grow flex">
        <div class="-mx-6 flex justify-center flex-wrap items-center flex-grow">
          <div class="px-2 w-8/12">
            <div class="p-4 border">
              <b-field
                label="Name"
                :type="$store.getters.hasErrors('name').type"
                :message="$store.getters.hasErrors('name').message"
              >
                <b-input v-model="name"></b-input>
              </b-field>
              <b-field label="Action">
                <b-select v-model="action" expanded>
                  <option
                    v-for="(action, index) in actions"
                    :value="action.action"
                    :key="index"
                  >
                    {{ action.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Path">
                <b-select v-model="path" expanded>
                  <option
                    v-for="(path, index) in paths"
                    :value="path.path"
                    :key="index"
                  >
                    {{ path.path }}
                  </option>
                </b-select>
              </b-field>
              <b-field
                label="Slug"
                :type="$store.getters.hasErrors('slug').type"
                :message="$store.getters.hasErrors('slug').message"
              >
                <b-input v-model="slug" disabled></b-input>
              </b-field>
              <div class="flex items-center justify-end">
                <b-button
                  class="mr-4"
                  v-if="$route.params.id"
                  type="is-danger is-light"
                  :disabled="$store.getters.isProcessing"
                  @click="onDelete()"
                  >Delete</b-button
                >
                <b-button
                  type="is-success"
                  :disabled="$store.getters.isProcessing"
                  @click="onSave"
                  >Save changes</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar";
import store from "@/store";
export default {
  components: {
    Sidebar
  },
  data: () => ({
    name: "",
    action: "",
    path: "",
    slug: "",
    actions: [],
    paths: []
  }),
  computed: {},
  watch: {
    action() {
      this.slug = `${this.action}-${this.path}`;
    },
    path() {
      this.slug = `${this.action}-${this.path}`;
    }
  },
  methods: {
    onSave() {
      store.dispatch("savePermissionsForm", {
        name: this.name,
        slug: this.slug,
        id: this.$route.params.id || false
      });
    },
    onDelete() {
      store.dispatch("deleteSelection", {
        message: `Do you really want to delete this permission?`,
        ids: [this.$route.params.id],
        path: "permissions",
        push: "Permissions"
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store
        .dispatch(to.meta.dispatch, { id: to.params.id || null })
        .then(res => {
          next(vm => {
            vm.actions = res.data.actions;
            vm.paths = res.data.models;
            if (to.params.id) {
              vm.name = res.data.permission.name;
              vm.action = _.split(res.data.permission.slug, "-")[0];
              vm.path = _.replace(
                res.data.permission.slug,
                `${_.split(res.data.permission.slug, "-")[0]}-`,
                ""
              );
            }
          });
        });
    }
  }
};
</script>
